<template>
  <div id="parent" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(submit)">
            <SdSelect
              rules="required"
              label="Country"
              v-model="parent.country.name"
            >
              <option v-for="country in countries" :key="country.id">
                {{ country.name }}
              </option>
            </SdSelect>

            <SdSelect
              rules="required"
              label="State"
              v-model="parent.state.name"
            >
              <option v-for="state in states" :key="state.id">
                {{ state.name }}
              </option>
            </SdSelect>

            <SdSelect
              rules="required"
              label="State LGA"
              v-model="parent.stateLga.name"
            >
              <option v-for="stateLga in stateLgas" :key="stateLga.id">
                {{ stateLga.name }}
              </option>
            </SdSelect>

            <SdInput
              type="text"
              rules="required"
              label="Family Name"
              v-model="parent.familyName"
              placeholder="Enter a name"
            />

            <SdInput
              type="text"
              rules="required"
              label="Name of Father"
              v-model="parent.nameOfFather"
              placeholder="Enter a name"
            />

            <!-- <b-field label="DoB of Father">
              <b-datepicker
                placeholder="Click to select..."
                icon="calendar-day"
                v-model="parent.dobOfFather"
                :date-formatter="date => date.toDateString()"
              ></b-datepicker>
            </b-field>-->

            <SdInput
              type="text"
              label="Phone of Father"
              :rules="{
                required: true,
                length: 13,
                regex: /234\d{10}$/,
              }"
              v-model="parent.phoneOfFather"
              placeholder="Enter phone number"
            />

            <!-- :rules="`email|compare:${parent.emailOfMother},mother`" -->
            <SdInput
              type="email"
              rules="required|email"
              label="Email of Father"
              v-model="parent.emailOfFather"
              placeholder="Enter email"
            />

            <SdInput
              type="text"
              rules="required"
              label="Name of Mother"
              v-model="parent.nameOfMother"
              placeholder="Enter a name"
            />

            <!-- <b-field label="DoB of Mother">
              <b-datepicker
                placeholder="Click to select..."
                icon="calendar-day"
                v-model="parent.dobOfMother"
                :date-formatter="date => date.toDateString()"
              ></b-datepicker>
            </b-field>-->

            <SdInput
              type="text"
              label="Phone of Mother"
              :rules="{
                required: true,
                length: 13,
                regex: /234\d{10}$/,
              }"
              v-model="parent.phoneOfMother"
              placeholder="Enter phone number"
            />

            <SdInput
              type="email"
              rules="required|email"
              label="Email of Mother"
              v-model="parent.emailOfMother"
              placeholder="Enter email"
            />

            <SdInput
              type="text"
              rules="required"
              label="House address"
              v-model="parent.houseAddress"
              placeholder="Enter house address"
            />

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import firebase from 'firebase/app'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'parent',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Parent',
      skipQuery: true,
      parent: {
        id: null,
        countryId: '164',
        stateId: null,
        stateLgaId: null,
        country: {
          name: 'Nigeria',
        },
        state: {
          name: null,
        },
        stateLga: {
          name: null,
        },
        familyName: '',
        nameOfFather: '',
        dobOfFather: '',
        phoneOfFather: '',
        emailOfFather: '',
        nameOfMother: '',
        dobOfMother: '',
        phoneOfMother: '',
        emailOfMother: '',
        houseAddress: '',
      },
      countries: [],
      states: [],
      stateLgas: [],
    }
  },
  apollo: {
    parent: {
      query: gql`
        query ParentQuery($id: ID!) {
          parent(id: $id) {
            id
            countryId
            stateId
            stateLgaId
            country {
              name
            }
            state {
              name
            }
            stateLga {
              name
            }
            familyName
            nameOfFather
            dobOfFather
            phoneOfFather
            emailOfFather
            nameOfMother
            dobOfMother
            phoneOfMother
            emailOfMother
            houseAddress
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    countries: {
      query: gql`
        query CountryQuery {
          countries {
            id
            name
          }
        }
      `,
    },
    states: {
      query: gql`
        query StateQuery {
          states {
            id
            name
          }
        }
      `,
    },
  },
  watch: {
    'parent.country.name'(newValue) {
      if (!newValue) return
      this.parent.countryId = this.countries.filter(
        (country) => country.name === newValue
      )[0].id
    },
    'parent.state.name'(newValue) {
      if (!newValue) return
      this.parent.stateId = this.states.filter(
        (state) => state.name === newValue
      )[0].id
      this.$apollo.addSmartQuery('stateLgas', {
        query: gql`
          query StateLgasQuery($stateId: ID!) {
            stateLgas(stateId: $stateId) {
              id
              name
            }
          }
        `,
        variables: {
          stateId: parseInt(this.parent.stateId),
        },
      })

      // this.$apollo.queries.stateLgas.refetch();
    },
    'parent.stateLga.name'(newValue) {
      if (!newValue) return
      if (this.stateLgas.length === 0) return
      this.parent.stateLgaId = this.stateLgas.filter(
        (stateLga) => stateLga.name === newValue
      )[0].id
    },
  },
  methods: {
    submit() {
      const countryId = parseInt(this.parent.countryId)
      const stateId = parseInt(this.parent.stateId)
      const stateLgaId = parseInt(this.parent.stateLgaId)
      const familyName = this.parent.familyName
      const nameOfFather = this.parent.nameOfFather.trim()
      const dobOfFather = this.parent.dobOfFather
      const phoneOfFather = this.parent.phoneOfFather
      const emailOfFather = this.parent.emailOfFather.trim()
      const nameOfMother = this.parent.nameOfMother.trim()
      const dobOfMother = this.parent.dobOfMother
      const phoneOfMother = this.parent.phoneOfMother
      const emailOfMother = this.parent.emailOfMother.trim()
      const houseAddress = this.parent.houseAddress.trim()

      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createParentQuery(
                $schoolId: Int!
                $countryId: Int!
                $stateId: Int!
                $stateLgaId: Int!
                $familyName: String!
                $nameOfFather: String!
                $dobOfFather: String
                $phoneOfFather: String!
                $emailOfFather: String
                $nameOfMother: String!
                $dobOfMother: String
                $phoneOfMother: String!
                $emailOfMother: String
                $houseAddress: String!
              ) {
                createParent(
                  input: {
                    schoolId: $schoolId
                    countryId: $countryId
                    stateId: $stateId
                    stateLgaId: $stateLgaId
                    familyName: $familyName
                    nameOfFather: $nameOfFather
                    dobOfFather: $dobOfFather
                    phoneOfFather: $phoneOfFather
                    emailOfFather: $emailOfFather
                    nameOfMother: $nameOfMother
                    dobOfMother: $dobOfMother
                    phoneOfMother: $phoneOfMother
                    emailOfMother: $emailOfMother
                    houseAddress: $houseAddress
                  }
                ) {
                  parent {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              countryId: countryId,
              stateId: stateId,
              stateLgaId: stateLgaId,
              familyName: familyName,
              nameOfFather: nameOfFather,
              dobOfFather: dobOfFather,
              phoneOfFather: phoneOfFather,
              emailOfFather: emailOfFather,
              nameOfMother: nameOfMother,
              dobOfMother: dobOfMother,
              phoneOfMother: phoneOfMother,
              emailOfMother: emailOfMother,
              houseAddress: houseAddress,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createParent.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/parents`)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateParentQuery(
                $id: Int!
                $countryId: Int!
                $stateId: Int!
                $stateLgaId: Int!
                $familyName: String!
                $nameOfFather: String!
                $dobOfFather: String
                $phoneOfFather: String!
                $emailOfFather: String
                $nameOfMother: String!
                $dobOfMother: String
                $phoneOfMother: String!
                $emailOfMother: String
                $houseAddress: String!
              ) {
                updateParent(
                  input: {
                    id: $id
                    countryId: $countryId
                    stateId: $stateId
                    stateLgaId: $stateLgaId
                    familyName: $familyName
                    nameOfFather: $nameOfFather
                    dobOfFather: $dobOfFather
                    phoneOfFather: $phoneOfFather
                    emailOfFather: $emailOfFather
                    nameOfMother: $nameOfMother
                    dobOfMother: $dobOfMother
                    phoneOfMother: $phoneOfMother
                    emailOfMother: $emailOfMother
                    houseAddress: $houseAddress
                  }
                ) {
                  parent {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.parent.id),
              countryId: countryId,
              stateId: stateId,
              stateLgaId: stateLgaId,
              familyName: familyName,
              nameOfFather: nameOfFather,
              dobOfFather: dobOfFather,
              phoneOfFather: phoneOfFather,
              emailOfFather: emailOfFather,
              nameOfMother: nameOfMother,
              dobOfMother: dobOfMother,
              phoneOfMother: phoneOfMother,
              emailOfMother: emailOfMother,
              houseAddress: houseAddress,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateParent.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/parents`)
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    const menus = [
      {
        name: 'Parents',
        route: `/school/${this.schoolId}/parents`,
      },
    ]

    if (this.$route.params.id !== 'new') {
      this.skipQuery = false
      menus.push({
        name: 'Parent Info',
        route: `/school/${this.schoolId}/parent_info/${this.$route.params.id}`,
      })
    }

    this.$store.commit('setSubMenus', menus)
  },
}
</script>

<style lang="scss" scoped></style>
